@import './modules/colors.scss';
@import './modules/fonts.scss';
@import './modules/devices';

.header {

    a {
      margin: 0 10px;
      font-size: 1.1em;

      &:hover{
        color: #cc4c2d;
      }
    }

    
    background-color: $primary-color;
    padding: 15px 0px;
    display: flex;
    box-shadow: -1px 2px 7px -2px rgb(87, 87, 87); 

    &__left{ 
      align-items: center;
      justify-content: center;
      display: flex;

      @include mobile{
        flex: 1;
      }

    }

    &__right{
      flex: 1;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    // @include mobile{
    //   padding: 20px 0px;
    //   flex-direction: column;
    // }

    &__logo {
      margin-left: 20px;
      display: flex;
      flex-direction: row;

      // @include mobile{
      //   margin: 10px 0px;
      // }

    }

    &__links{
      display: flex;
      flex: 1;
      margin-right: 20px;
      align-items: center;
      justify-content: flex-end;

      // @include mobile{
      //   justify-content: center;
      //   margin-right: 0px;
      // }


    }
}

.links__link{

  cursor: pointer;

  &--inactive{
    font-family: $primary-font;
    color: $secondary-color;
  }

  &--active{
    font-family: $primary-font;
    cursor: pointer;
    color: #cc4c2d;
  }

}

.logo__name{
  font-size: 2vw;
  color: white;
  font-family: $primary-font;

  // @include desktop{
  //   font-size: 2vw;
  // }

  // @include tablet{
  //   font-size: 4vw;
  // }

  // @include mobile{
  //   font-size: 6vw;
  // }


  &--last{
    color: transparent;
    font-size: 2vw;
    font-family: $primary-font;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: rgb(255, 255, 255);

    // @include desktop{
    //   font-size: 2vw;
    // }
  
    // @include tablet{
    //   font-size: 4vw;
    // }
  
    // @include mobile{
    //   font-size: 6vw;
    // }

  }

}