
.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:black;
    background-repeat: no-repeat;
  }

  .name__container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:black;
  }
  
  .subtext{
    color: #909090;
    font-size: 2em;
    margin-top: -10px;
    opacity: 0.7;

    @include desktop{
      font-size: 2vw;
    }
  
    @include tablet{
      font-size: 2vw;
    }
  
    @include mobile{
      margin-top: 0px;
      font-size: 3vw;
    }
    /* font-family: 'Gothic A1', sans-serif; */
  }
  
  .name{
    color: #E8E8E8;
    font-size: 4em;
    /* font-family: 'Muli', sans-serif; */
    font-family: 'Teko', sans-serif;
    font-family: 'Gothic A1', sans-serif;
    /* content: "Read this: "; */
    font-weight: 100;

    @include desktop{
      font-size: 4em;
    }
  
    @include tablet{
      font-size: 4vw;
    }
  
    @include mobile{
      font-size: 6vw;
    }
    
  }
  
  .firstChar{
    margin: auto 0;
    color: white;
    font-size: 4.5em;
    font-family: 'Muli', sans-serif;
  }
  
  .background{
    height: 100vh;
    width: 100%;
    position: absolute;
  }
  
  .menu__item{
    color: #909090;
    font-size: 1.1em;
    font-family: 'Muli', sans-serif;
    margin: 10px;
  }
  
  .menu__items{
    position: absolute;
  }
  
  .arrow {
    height: 48px;
    transform: rotateZ(-90deg);
    opacity: 1;
  }
  
  img:hover{
    cursor: pointer;
  }
  

